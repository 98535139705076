import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGES } from '../layout/theme';

const WithUs = () => {
  return (
    <>
      <div className="banner-animate">
        <span className="stars">
          <img src={IMAGES.Star1} alt="Stars" />
        </span>
        <span className="planet planet1 animate-this">
          <img src={IMAGES.Planet1} alt="Planet" />
        </span>
        <span className="planet planet2 animate-this">
          <img src={IMAGES.Planet2} alt="Planet" />
        </span>
        <span className="planet planet3 animate-this">
          <img src={IMAGES.Planet3} alt="Planet" />
        </span>
        <span className="planet planet4 animate-this">
          <img src={IMAGES.Planet4} alt="Planet" />
        </span>
        <span className="planet planet5 animate-this">
          <img src={IMAGES.Planet4} alt="Planet" />
        </span>
        <span className="shooting-star shooting-star1">
          <img src={IMAGES.ShootingStar} alt="Shooting Star" />
        </span>
        <span className="shooting-star shooting-star2">
          <img src={IMAGES.ShootingStar} alt="Shooting Star" />
        </span>
        <span className="shooting-star shooting-star3">
          <img src={IMAGES.ShootingStar} alt="Shooting Star" />
        </span>
        <span className="shooting-star shooting-star4">
          <img src={IMAGES.ShootingStar} alt="Shooting Star" />
        </span>
        <span className="shooting-star shooting-star5">
          <img src={IMAGES.ShootingStar} alt="Shooting Star" />
        </span>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="with-us-content">
              <span className="sub-title">WE FOCUS ON RELATIONSHIP</span>
              <h4 className="h4-title">
                Schedule a free consultation call <span>with us</span>
              </h4>
              <Link to="https://calendar.app.google/Fjfnvwb5umKAUCX98" target="_blank" className="sec-btn lg">
                <span>Join Us Now</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithUs;
