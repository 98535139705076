import React from 'react';
import FeaturesBox from '../element/FeaturesBox';
import { IMAGES } from '../layout/theme';

const featuresData = [
  {
    title: 'Idea & Analysis Gathering',
    image: IMAGES.IdeaImg,
    details: 'We use our expertise to identify the best solution for your business. Be it webflow, shopify or a fully custom solution.',
  },
  {
    title: 'Design & Development',
    image: IMAGES.DevelopImg,
    details:
      'Our team of expert designers and developers will create a beautiful, high-performance website that will massively increase conversion.',
  },
  {
    title: 'Testing & Lunching',
    image: IMAGES.LaunchImg,
    details: 'We beleive in testing and retesting to ensure that your website remains up and bug free.',
  },
];

const Features = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="center-title">
            <span id="how-we-work" className="sub-title">
              HOW WE WORK
            </span>
            <h2 className="h2-title">Behind The Story</h2>
          </div>
        </div>
      </div>
      <div className="features-list">
        <div className="row">
          {featuresData.map((item, ind) => (
            <div className="col-lg-4" key={ind}>
              <FeaturesBox image={item.image} title={item.title} details={item.details} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
