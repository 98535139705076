import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { IMAGES } from '../layout/theme';
import MainBanner from '../components/MainBanner';
// import PartnerSlider from '../components/PartnerSlider';
import Features from '../components/Features';
import AboutBlog from '../components/AboutBlog';
import WithUs from '../components/WithUs';
import ServicesList from '../components/ServicesList';
import PortfolioTab from '../components/PortfolioTab';
import TouchStart from '../components/TouchStart';
import { ScrollContext } from '../App';

function delayScroll(cb) {
  setTimeout(() => {
    cb();
  }, 100);
}

const Home = (props) => {
  const scrollCtx = useContext(ScrollContext);
  switch (props.section) {
    case 'about':
      delayScroll(() => scrollCtx.executeScroll(scrollCtx.aboutUsRef));
      break;
    case 'services':
      delayScroll(() => scrollCtx.executeScroll(scrollCtx.servicesRef));
      break;
    case 'contact':
      delayScroll(() => scrollCtx.executeScroll(scrollCtx.contactUsRef));
      break;
    default:
      break;
  }
  return (
    <>
      <section className="main-banner" id="main-banner">
        <MainBanner />
        <div className="container">
          <div className="rocket-img active-rocket">
            <img src={IMAGES.Roket} alt="Rocket" />
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9">
              <div className="banner-content">
                <h1 className="h1-title wow fadeup-animation">Boost your eCommerce business</h1>
                <Link to="https://calendar.app.google/Fjfnvwb5umKAUCX98" target="_blank" className="sec-btn">
                  <span>Schedule a free consultation</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Link onClick={() => scrollCtx.executeScroll(scrollCtx.mainFeaturesRef)} className="scroll-down">
          <img className="scroll-down-image" src={IMAGES.ScrollDown} alt="Scroll Down" />
        </Link>
      </section>
      {/* <PartnerSlider /> */}
      {/* Features Start  */}
      <section ref={scrollCtx.mainFeaturesRef} className="main-features">
        <Features />
      </section>
      {/*  Aboout Us Start  */}
      <section ref={scrollCtx.aboutUsRef} className="main-about-us">
        <AboutBlog />
      </section>
      {/* <!-- More With Us Start --> */}
      <section className="main-with-us">
        <WithUs />
      </section>
      {/*  Services Start  */}
      <section ref={scrollCtx.servicesRef} className="main-services">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="center-title">
                <span className="sub-title" id="services">
                  SERVICES
                </span>
                <h2 className="h2-title">What We Provide</h2>
              </div>
            </div>
          </div>
          <ServicesList />
        </div>
      </section>
      {/*  Portfolio Start  */}
      <section className="main-portfolio">
        <PortfolioTab />
      </section>
      {/* <section className="main-testimonial"> */}
      {/*   <div className="container"> */}
      {/*     <div className="row"> */}
      {/*       <div className="col-12"> */}
      {/*         <div className="center-title"> */}
      {/*           <span className="sub-title">TESTIMONIAL</span> */}
      {/*           <h2 className="h2-title">What Our Clients Say</h2> */}
      {/*         </div> */}
      {/*       </div> */}
      {/*     </div> */}
      {/*     <TestimonialBlog /> */}
      {/*   </div> */}
      {/* </section> */}
      {/* <!-- Get In Touch Start --> */}
      <section ref={scrollCtx.contactUsRef} className="main-contact">
        <div className="container">
          <TouchStart />
        </div>
      </section>
      {/*  Our Blog Start  */}
      {/* <section className="main-our-blog"> */}
      {/*   <OurBlog /> */}
      {/* </section> */}
    </>
  );
};

export default Home;
