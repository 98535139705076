import { createContext, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from './router/Router';

import '../node_modules/react-modal-video/css/modal-video.css';
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom.css';
import './assets/vendor/themify/themify-icons.css';
import './assets/css/animate.css';
import './assets/css/style.css';

export const ScrollContext = createContext();

function App() {
  const scrollContextValue = {
    mainFeaturesRef: useRef(null),
    aboutUsRef: useRef(null),
    servicesRef: useRef(null),
    contactUsRef: useRef(null),
    executeScroll: (ref) => {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    },
  };
  return (
    <>
      <ScrollContext.Provider value={scrollContextValue}>
        <BrowserRouter basename="/">
          <Router />
        </BrowserRouter>
      </ScrollContext.Provider>
    </>
  );
}

export default App;
