import React, { useReducer, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

import { menuData } from './Menus';
import { IMAGES } from './theme';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: '',
};

const Header = () => {
  const [headerFix, setheaderFix] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState('Home');
  const [state, setState] = useReducer(reducer, initialState);
  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: '' });
    }
  };
  /// Path
  let path = useLocation().pathname;

  function CommanScroll() {
    setheaderFix(window.scrollY > 50);
  }

  useEffect(() => {
    menuData.map((data) => {
      if (data.to === path) {
        setActiveMenu(data.title);
      }
      data.content?.map((item) => {
        if (item.to === path) {
          setActiveMenu(data.title);
        }

        return item;
      });
      return data;
    });

    window.addEventListener('scroll', CommanScroll);
    return () => {
      window.removeEventListener('scroll', CommanScroll);
    };
  }, [path]);
  return (
    <>
      <header
        className={`site-header
                ${headerFix ? 'sticky-header' : ''}`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="header-box">
                <div className="site-branding">
                  <Link to={'/'} title="Hexsis">
                    <img src={IMAGES.headlogo} alt="Logo" />
                    <img src={IMAGES.headlogoMain} className="sticky-logo" alt="Logo2" />
                  </Link>
                </div>
                <div className="header-menu">
                  <nav className={`main-navigation ${toggleMenu ? 'toggle-menu' : ''}`}>
                    <button className="toggle-button" onClick={() => setToggleMenu(!toggleMenu)}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>
                    <ul className="menu">
                      {menuData.map((item, ind) => {
                        if (item.classChange === 'sub-items') {
                          return (
                            <li
                              className={`${item.classChange ? 'sub-items' : ''}${item.title === state.active ? ' active-sub-menu' : ''}${activeMenu === item.title ? ' active' : ''}`}
                              key={ind}
                            >
                              <Link
                                to={item.to}
                                title={item.title}
                                onClick={() => {
                                  handleMenuActive(item.title);
                                }}
                              >
                                {item.title}
                              </Link>
                              <Collapse in={state.active === item.title ? true : false}>
                                <ul className={`sub-menu ${item.title === state.active ? 'd-block' : ''}`}>
                                  {item.content &&
                                    item.content.map((data, index) => (
                                      <li key={index}>
                                        <Link to={data.to} title={data.title}>
                                          {data.title}
                                        </Link>
                                      </li>
                                    ))}
                                </ul>
                              </Collapse>
                            </li>
                          );
                        } else {
                          return (
                            <li
                              className={`${item.title === state.active ? ' active-sub-menu' : ''}${activeMenu === item.title ? ' active' : ''}`}
                              key={ind}
                            >
                              <Link to={item.to} title={item.title}>
                                {item.title}
                              </Link>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </nav>
                  <div className="black-shadow"></div>
                </div>
                <div className="header-search">
                  <Link to="https://calendar.app.google/Fjfnvwb5umKAUCX98" className="sec-btn" target="_blank">
                    <span>Schedule a Call</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
