import React from 'react';
import { IMAGES } from '../layout/theme';
import ServiceBlog, { ServiceBlog2 } from '../element/ServiceBlog';

const ServicesList = () => {
  return (
    <div className="services-list">
      <div className="row align-items-center">
        <div className="col-xl-4 col-lg-6">
          <ServiceBlog title="Web Development" image={IMAGES.Web1} details="We build custom, responsive websites to grow your business." />
          <ServiceBlog title="Web Design" image={IMAGES.Web2} details="We create stunning web designs to wow your users." />
          <ServiceBlog
            title="SEO & Marketing"
            image={IMAGES.Seo}
            details="We optimize your site and drive growth with targeted marketing."
          />
        </div>
        <div className="col-lg-4 d-none d-xl-inline-block">
          <div className="services-img wow fadeIn" data-wow-delay="0.4s">
            <img src={IMAGES.ServiceImg} alt="Services" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <ServiceBlog2 title="Research" image={IMAGES.Research} details="We conduct research to inform strategies for your success." />
          <ServiceBlog2
            title="Maintanance"
            image={IMAGES.Maintan}
            details="We provide ongoing website maintenance to ensure optimal performance and security."
          />
          <ServiceBlog2
            title="24x7 Support"
            image={IMAGES.Support}
            details="We offer 24/7 support to answer your queries and keep your site running."
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesList;
