import React, { useState } from 'react';
import { IMAGES } from '../layout/theme';

const portfolioData = [
  {
    image: IMAGES.Portfolio1,
    design: 'md-6',
    category: 'shopify',
    title: 'Shopify Plugin',
    slug: 'campaign-king',
    details: 'Coming Soon',
  },
  {
    image: IMAGES.Portfolio2,
    design: 'md-3',
    category: 'webflow',
    title: 'Webflow Template',
    slug: 'super-webflow',
    details: 'Coming Soon',
  },
  {
    image: IMAGES.Portfolio3,
    design: 'md-3',
    category: 'webflow',
    title: 'Webflow Template',
    slug: 'super-webflow',
    details: 'Coming Soon',
  },
];

const tabHeading = [
  { id: 'all', title: 'All', filterItem: '' },
  { id: 'webflow', title: 'Webflow', filterItem: '' },
  { id: 'shopify', title: 'Shopify', filterItem: '' },
];

const PortfolioTab = () => {
  const [activeTab, setActiveTab] = useState('all');
  const filteredPortfolio = activeTab === 'all' ? portfolioData : portfolioData.filter((item) => item.category === activeTab);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="center-title">
              <span className="sub-title" id="portfolio">
                PORTFOLIO
              </span>
              <h2 className="h2-title">See Our Work</h2>
              <div className="portfolio-tabbing">
                <ul className="clearfix" id="filters">
                  {tabHeading.map((data, i) => (
                    <li key={i} onClick={() => setActiveTab(data.id)}>
                      <span className={`filter ${activeTab === data.id ? 'active' : ''}`}>{data.title}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio-list-box">
        <div className="portfoliolist" id="portfoliolist">
          {filteredPortfolio.map((data, i) => (
            <div className={`portfolio  ${data.design}`} style={{ display: 'inline-block' }} key={i}>
              <div className="portfolio-wrapper">
                <div className="portfolio-img back-img" style={{ backgroundImage: `url(${data.image})` }}></div>
                <div className="portfolio-wrapper-text">
                  <h3 className="h3-title">{data.title}</h3>
                  <p>{data.details}</p>
                  {/* <Link to={'/portfolio/' + data.category + '/' + data.slug}> */}
                  {/*   <i className="fa fa-plus" /> */}
                  {/* </Link> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PortfolioTab;
