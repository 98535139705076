import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { IMAGES } from '../layout/theme';

const TouchStart = () => {
  emailjs.init('SbPdp4vHGUJGgwkLS');
  const firstName = useRef();
  const lastName = useRef();
  const email = useRef();
  const phone = useRef();
  const message = useRef();
  const sendEmail = (e) => {
    const mssg = {
      firstName: firstName.current.value,
      lastName: lastName.current.value,
      email: email.current.value,
      phone: phone.current.value,
      message: message.current.value,
    };
    e.preventDefault();
    emailjs
      .send('service_q2jvqfs', 'template_gn1ti1a', {
        from_name: firstName.current.value + ' ' + lastName.current.value,
        to_name: 'Kazi Waseef',
        message: JSON.stringify(mssg, null, 2),
        reply_to: email.current.value,
      })
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        },
      );
    e.target.reset();
    Swal.fire({ title: 'Good job!', text: 'form successfuly submmited', icon: 'success' });
  };
  return (
    <div className="row align-items-center" id="contact">
      <div className="col-lg-6 order-lg-1 order-2">
        <div className="contact-content">
          <span className="sub-title">GET IN TOUCH</span>
          <h2 className="h2-title">Get Free Analysis Consultancy</h2>
          <div className="contact-form">
            <form className="dzForm" onSubmit={sendEmail}>
              <div className="dzFormMsg"></div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-box">
                    <input ref={firstName} name="dzName" type="text" required className="form-input" placeholder="First Name" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-box">
                    <input ref={lastName} name="dzOther[last_name]" type="text" className="form-input" placeholder="Last Name" required />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-box">
                    <input type="text" ref={email} name="dzEmail" className="form-input" placeholder="Email Address" required />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-box">
                    <input type="text" ref={phone} name="dzOther[phone]" className="form-input" placeholder="Phone No." required />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-box">
                    <textarea ref={message} name="dzMessage" className="form-input" required placeholder="Message..." />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-box">
                    <button name="submit" type="submit" value="Submit" className="sec-btn">
                      <span>Submit Now</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-lg-6 order-lg-2 order-1">
        <div className="contact-img ">
          <img src={IMAGES.ConatctImg} alt="Contact" />
        </div>
      </div>
    </div>
  );
};

export default TouchStart;
