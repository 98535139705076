const FeaturesBox = (props) => {
  return (
    <div className="features-box">
      <div className="features-img">
        <img src={props.image} alt={props.title} />
      </div>
      <h3 className="h3-title">{props.title}</h3>
      <p>{props.details}</p>
    </div>
  );
};

export default FeaturesBox;
