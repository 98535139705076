export const menuData = [
  {
    title: 'Home',
    to: '/',
  },
  {
    title: 'About Us',
    to: '/about',
  },
  {
    title: 'Services',
    to: '/services',
  },
  {
    title: 'Contact Us',
    to: '/contact',
  },
  // {
  //   title: 'Pages',
  //   classChange: 'sub-items',
  //   content: [
  //     {
  //       title: 'Pricing',
  //       to: '/pricing',
  //     },
  //     {
  //       title: 'Portfolio',
  //       to: '/portfolio',
  //     },
  //     {
  //       title: 'Portfolio-Detail',
  //       to: '/portfolio-detail',
  //     },
  //     {
  //       title: 'Team',
  //       to: '/team',
  //     },
  //     {
  //       title: 'FAQ',
  //       to: '/faq',
  //     },
  //   ],
  // },
  // {
  //   title: 'Blog',
  //   classChange: 'sub-items',
  //   content: [
  //     {
  //       title: 'Blog List',
  //       to: '/blog-list',
  //     },
  //     {
  //       title: 'Blog Detail',
  //       to: '/blog-detail',
  //     },
  //   ],
  // },
];
