import { Link } from 'react-router-dom';
import { IMAGES } from '../layout/theme';

const AboutBlog = () => {
  return (
    <div className="container">
      <div className="row align-items-center" id="about-us">
        <div className="col-lg-6 order-lg-1 order-2">
          <div className="about-content">
            <span className="sub-title">ABOUT US</span>
            <h2 className="h2-title">We Turn Your Ideas Into Design</h2>
            <p>
              At Hexsis, we believe that every business has its own story, and we’re here to help you tell yours through beautiful,
              functional, and high-performing eCommerce websites. Whether you're launching a new brand or scaling an existing one, we
              specialize in turning your vision into a seamless digital experience.
            </p>
            <ul>
              <li>We listen, design, and deliver custom Shopify or Webflow solutions, tailored to your eCommerce needs.</li>
              <li>
                Our creative team ensures your website looks great and works flawlessly to boost user experience and sales. From design to
                development, we bring your ideas to life with custom solutions.{' '}
              </li>
              <li>Let’s build something amazing together.</li>
            </ul>
            {/* <Link to="/about-us" className="sec-btn lg" title="Read More"> */}
            {/*   <span>Read More</span> */}
            {/* </Link> */}
          </div>
        </div>
        <div className="col-lg-6 order-lg-2 order-1">
          <div className="about-img">
            <img src={IMAGES.AboutImg} alt="About Us" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBlog;
