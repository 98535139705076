export const IMAGES = {
  headlogo: require('./../assets/images/logo.png'),
  headlogoMain: require('./../assets/images/logo-main.png'),
  Star1: require('./../assets/images/stars.png'),
  SmokeImg1: require('./../assets/images/smoke-img1.png'),
  SmokeImg2: require('./../assets/images/smoke-img2.png'),
  Planet1: require('./../assets/images/planet1.png'),
  Planet2: require('./../assets/images/planet2.png'),
  Planet3: require('./../assets/images/planet3.png'),
  Planet4: require('./../assets/images/planet4.png'),
  ShootingStar: require('./../assets/images/shooting-star.png'),
  Roket: require('./../assets/images/rocket.png'),
  ScrollDown: require('./../assets/images/scroll-down.png'),
  Partner1: require('./../assets/images/partners-img1.png'),
  Partner2: require('./../assets/images/partners-img2.png'),
  Partner3: require('./../assets/images/partners-img3.png'),
  Partner4: require('./../assets/images/partners-img4.png'),
  Partner5: require('./../assets/images/partners-img5.png'),
  Partner6: require('./../assets/images/partners-img6.png'),
  IdeaImg: require('./../assets/images/idea-img.png'),
  DevelopImg: require('./../assets/images/design-development-img.png'),
  LaunchImg: require('./../assets/images/testing-launching-img.png'),
  AboutImg: require('./../assets/images/about-img.png'),
  Web1: require('./../assets/images/web-development.png'),
  Web2: require('./../assets/images/web-design.png'),
  Seo: require('./../assets/images/seo-marketing.png'),
  Research: require('./../assets/images/research.png'),
  Maintan: require('./../assets/images/maintanance.png'),
  Support: require('./../assets/images/support.png'),
  ServiceImg: require('./../assets/images/service-img.png'),
  Portfolio1: require('./../assets/images/portfolio-img1.jpg'),
  Portfolio2: require('./../assets/images/portfolio-img2.jpg'),
  Portfolio3: require('./../assets/images/portfolio-img3.jpg'),
  Portdetail1: require('./../assets/images/portfolio-detail-img1.jpg'),
  Portdetail2: require('./../assets/images/portfolio-detail-img2.jpg'),
  Portdetail3: require('./../assets/images/portfolio-detail-img3.jpg'),
  TestImg1: require('./../assets/images/testimonial-img1.jpg'),
  TestImg2: require('./../assets/images/testimonial-img2.jpg'),
  TestImg3: require('./../assets/images/testimonial-img3.jpg'),
  Quote: require('./../assets/images/quote.png'),
  ConatctImg: require('./../assets/images/contact-img.png'),
  BlogImg1: require('./../assets/images/blog-img1.jpg'),
  BlogImg2: require('./../assets/images/blog-img2.jpg'),
  BlogImg3: require('./../assets/images/blog-img3.jpg'),
  BlogImg4: require('./../assets/images/blog-img4.jpg'),
  BlogImg5: require('./../assets/images/blog-img5.jpg'),
  BlogImg6: require('./../assets/images/blog-img6.jpg'),
  BlogImg7: require('./../assets/images/blog-img7.jpg'),
  CounterImg: require('./../assets/images/counter-img.png'),
  ExperienceImg: require('./../assets/images/experience-img.png'),
  TeamImg1: require('./../assets/images/team-img1.jpg'),
  TeamImg2: require('./../assets/images/team-img2.jpg'),
  TeamImg3: require('./../assets/images/team-img3.jpg'),
  TeamImg4: require('./../assets/images/team-img4.jpg'),
  TeamImg5: require('./../assets/images/team-img5.jpg'),
  TeamImg6: require('./../assets/images/team-img6.jpg'),
  FaqImg: require('./../assets/images/faq-img.png'),
  recent1: require('./../assets/images/recent-post-img1.jpg'),
  recent2: require('./../assets/images/recent-post-img2.jpg'),
  recent3: require('./../assets/images/recent-post-img3.jpg'),
  recent4: require('./../assets/images/recent-post-img4.jpg'),
  gallery1: require('./../assets/images/gallery-img1.jpg'),
  gallery2: require('./../assets/images/gallery-img2.jpg'),
  gallery3: require('./../assets/images/gallery-img3.jpg'),
  gallery4: require('./../assets/images/gallery-img4.jpg'),
  gallery5: require('./../assets/images/gallery-img5.jpg'),
  gallery6: require('./../assets/images/gallery-img6.jpg'),
  largeGallery1: require('./../assets/images/gallery-large-img1.jpg'),
  largeGallery2: require('./../assets/images/gallery-large-img2.jpg'),
  largeGallery3: require('./../assets/images/gallery-large-img3.jpg'),
  largeGallery4: require('./../assets/images/gallery-large-img4.jpg'),
  largeGallery5: require('./../assets/images/gallery-large-img5.jpg'),
  largeGallery6: require('./../assets/images/gallery-large-img6.jpg'),
  advertising: require('./../assets/images/advertising-img.jpg'),
  Comment1: require('./../assets/images/detail-comment-img1.jpg'),
  Comment2: require('./../assets/images/detail-comment-img2.jpg'),
  Comment3: require('./../assets/images/detail-comment-img3.jpg'),
  Quoto: require('./../assets/images/double-quote-2.png'),
  CallIcon: require('./../assets/images/call-icon.png'),
  MapIcon: require('./../assets/images/map-icon.png'),
  MailIcon: require('./../assets/images/mail-icon.png'),
};
