import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGES } from './theme';
import MainBanner from '../components/MainBanner';

const Footer = () => {
  let date = new Date();
  return (
    <>
      <footer className="site-footer">
        <MainBanner />
        <div className="container">
          <div className="footer-info">
            <div className="row align-items-center">
              <div className="col-lg-4 order-lg-1 order-2">
                <div className="footer-call">
                  <p>Schedule a Call:</p>
                  <Link to="https://calendar.app.google/Fjfnvwb5umKAUCX98" target="_blank">
                    Book a slot
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 order-lg-2 order-1">
                <div className="footer-logo">
                  <Link to={'/'}>
                    <img src={IMAGES.headlogo} alt="Logo" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 order-lg-3 order-3">
                <div className="social-icon">
                  <Link to={'https://hxs.is/fb'} target="_blank">
                    <i className="fa fa-facebook" />
                  </Link>
                  <Link to={'https://hxs.is/linkedin'} target="_blank">
                    <i className="fa fa-linkedin" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-9 col-md-6 order-lg-1 order-1">
              <div className="footer-about">
                <h3 className="h3-title footer-title">About Us</h3>
                <p>
                  At Hexsis, we believe that every business has its own story, and we’re here to help you tell yours through beautiful,
                  functional, and high-performing eCommerce websites. Whether you're launching a new brand or scaling an existing one, we
                  specialize in turning your vision into a seamless digital experience.
                </p>
                <Link to="https://calendar.app.google/Fjfnvwb5umKAUCX98" target="_blank" className="sec-btn" title="Join Us Now">
                  <span>Join Us Now</span>
                </Link>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 order-lg-2 order-3"> */}
            {/*   <div className="our-links"> */}
            {/*     <h3 className="h3-title footer-title">Our Links</h3> */}
            {/*     <ul> */}
            {/*       <li> */}
            {/*         <Link to={'/'}>Home</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/about-us'}>About Us</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/services'}>Services</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/portfolio'}>Portfolio</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/contact'}>Contact Us</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/pricing'}>Pricing</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/team'}>Team</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/faq'}>FAQ</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/blog-list'}>Blog</Link> */}
            {/*       </li> */}
            {/*     </ul> */}
            {/*   </div> */}
            {/* </div> */}
            {/* <div className="col-lg-3 col-md-6 order-lg-3 order-3"> */}
            {/*   <div className="footer-service"> */}
            {/*     <h3 className="h3-title footer-title">Our Services</h3> */}
            {/*     <ul> */}
            {/*       <li> */}
            {/*         <Link to={'/services'}>Web Design</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/services'}>Web Development</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/services'}>SEO Marketing</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/services'}>Research & Idea</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/services'}>Mobile App</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'/services'}>UI / UX</Link> */}
            {/*       </li> */}
            {/*     </ul> */}
            {/*   </div> */}
            {/* </div> */}
            <div className="col-lg-3 col-md-6 order-lg-4 order-2">
              <div className="footer-contact">
                <h3 className="h3-title footer-title">Contact Us</h3>
                <div className="footer-contact-link">
                  <span className="icon">
                    <i className="fa fa-map-marker" />
                  </span>
                  <Link
                    to={'#'}
                    title="2803 Philadelphia Pike
Suite B #1232, Claymont, DE 19703"
                  >
                    2803 Philadelphia Pike Suite B #1232, Claymont, DE 19703
                  </Link>
                </div>
                <div className="footer-contact-link">
                  <span className="icon">
                    <i className="fa fa-phone" />
                  </span>
                  <Link to="https://calendar.app.google/Fjfnvwb5umKAUCX98" title="Book a Slot">
                    Book a Slot
                  </Link>
                </div>
                <div className="footer-contact-link">
                  <span className="icon">
                    <i className="fa fa-envelope" />
                  </span>
                  <Link to={'mailto:waseef@hexsis.com'} title="Mail on waseef@hexsis.com">
                    waseef@hexsis.com
                  </Link>
                  <Link to={'#mailto:sumaya@hexsis.com'} title="Mail on sumaya@hexsis.com">
                    sumaya@hexsis.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-last">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="copy-right">
                <p>
                  Copyright © {date.getFullYear()}{' '}
                  <Link to="https://hexsis.com/" target="_blank">
                    Hexsis LLC
                  </Link>
                  . All rights reserved.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-6"> */}
            {/*   <div className="footer-last-link"> */}
            {/*     <ul> */}
            {/*       <li> */}
            {/*         <Link to={'#'}>Privacy Policy</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'#'}>Terms Of Service</Link> */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         <Link to={'#'}>Legal</Link> */}
            {/*       </li> */}
            {/*     </ul> */}
            {/*   </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
