import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import Home from '../pages/Home';
// import AboutUs from '../pages/AboutUs';
// import Services from '../pages/Services';
// import Pricing from '../pages/Pricing';
// import Portfolio from '../pages/portfolio';
// import PortfolioDetail from '../pages/portfolio/Detail';
// import Team from '../pages/Team';
// import Faq from '../pages/Faq';
// import BlogList from '../pages/BlogList';
// import BlogDetail from '../pages/BlogDetail';
// import ContactUs from '../pages/ContactUs';

import Footer from '../layout/Footer';
import Header from '../layout/Header';
import ScrollToTop from '../layout/ScrollToTop';
import Particls from '../layout/Particls';

const allRoute = [
  { url: '/', element: <Home section="home" /> },
  { url: '/about', element: <Home section="about" /> },
  { url: '/services', element: <Home section="services" /> },
  { url: '/contact', element: <Home section="contact" /> },
  // { url: 'about-us', element: <AboutUs /> },
  // { url: 'services', element: <Services /> },
  // { url: 'pricing', element: <Pricing /> },
  // { url: 'portfolio', element: <Portfolio /> },
  // { url: 'portfolio/:category/:slug', element: <PortfolioDetail /> },
  // { url: 'team', element: <Team /> },
  // { url: 'faq', element: <Faq /> },
  // { url: 'blog-list', element: <BlogList /> },
  // { url: 'blog-detail', element: <BlogDetail /> },
  // { url: 'contact', element: <ContactUs /> },
];

const Router = () => {
  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          {allRoute.map((data, ind) => (
            <Route path={`${data.url}`} key={ind} element={data.element} />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  return (
    <div className="main">
      <Header />
      <Outlet />
      <Footer />
      <Particls />
    </div>
  );
}

export default Router;
