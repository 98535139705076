const ServiceBlog = (props) => {
  return (
    <div className="service-box">
      <div className="service-box-text">
        <h3 className="h3-title">{props.title}</h3>
        <p>{props.details}</p>
      </div>
      <div className="service-icon">
        <img src={props.image} alt={props.title} />
        <img src={props.image} className="hover-img" alt="Web Development" />
      </div>
    </div>
  );
};
const ServiceBlog2 = (props) => {
  return (
    <div className="service-box left">
      <div className="service-icon">
        <img src={props.image} alt={props.title} />
        <img src={props.image} className="hover-img" alt={props.title + ' hover'} />
      </div>
      <div className="service-box-text">
        <h3 className="h3-title">{props.title}</h3>
        <p>{props.details}</p>
      </div>
    </div>
  );
};

export { ServiceBlog2 };
export default ServiceBlog;
